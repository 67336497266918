import { useStaticQuery, graphql } from "gatsby"

export const useAbout = () => {
  const data = useStaticQuery(
    graphql`
      query MyQueryAbout {
        allContentfulQuienesSomos {
          edges {
            node {
              title
              paragraph2
              paragraph3
              paragraph1 {
                paragraph1
              }
              node_locale
            }
          }
        }
      }
    `
  )
  return data.allContentfulQuienesSomos.edges.map(i => ({
    lang: i.node.node_locale,
    title: i.node.title,
    paragraph1: i.node.paragraph1.paragraph1,
    paragraph2: i.node.paragraph2,
    paragraph3: i.node.paragraph3,
  }))
}
