import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { CardCarousel } from "../elements/Cards"
import { useAbout } from "../../utils/useAboutSection"
import { useLanguageContext } from "../../utils/LanguageContext"

const About = () => {
  const { toggle } = useLanguageContext()
  const data = useAbout()
  const langFilteredEs = data.filter(i => i.lang === "es")
  const langFilteredEn = data.filter(i => i.lang === "en")

  return (
    <div className="flex flex-col lg:flex-row md:flex md:justify-between items-center px-4 pt-4 lg:pt-8 md:mb-16">
      <div className="lg:w-1/2 pb-8">
        <h1 className="h1-mobile lg:hidden">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <h1 className="h1-desktop hidden lg:block lg:pt-4">
          {toggle === "es"
            ? langFilteredEs.map(i => i.title)
            : langFilteredEn.map(i => i.title)}
        </h1>
        <p className="py-2 max-w-sm">
          <strong>El Estar </strong>{" "}
          {toggle === "es"
            ? langFilteredEs.map(i => i.paragraph1)
            : langFilteredEn.map(i => i.paragraph1)}
        </p>
        <p className="py-2 max-w-sm">
          {toggle === "es"
            ? langFilteredEs.map(i => i.paragraph2)
            : langFilteredEn.map(i => i.paragraph2)}
        </p>
        <p className="py-2 max-w-sm">
          <strong>El Estar </strong>{" "}
          {toggle === "es"
            ? langFilteredEs.map(i => i.paragraph3)
            : langFilteredEn.map(i => i.paragraph3)}
        </p>
      </div>
      <StaticImage
        height={470}
        src="../../images/2.png"
        placeholder="tracedSVG"
        alt="download picture"
        className="flex-shrink-0 mb-2 lg:block lg:mx-4 transform lg:scale-105  h-96 lg:w-64"
      />
      <CardCarousel />
    </div>
  )
}

export default About
