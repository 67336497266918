import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/sections/About"

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="Quienes Somos" />
      <About />
    </Layout>
  )
}

export default AboutPage
